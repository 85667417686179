import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class StatService {
  getStats() {
    return axios.get("vsolstats")
  }

  getVersion() {
    return axios.get("system/version", { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getFrontendVersion() {
    return axios.get("frontend/version")
  }
}
