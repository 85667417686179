
import { computed, ref } from "vue"
import ResultSet from "@/models/ResultSet"
import { useRouter } from "vue-router"
import ReleasesService from "@/services/ReleasesService"
import StatService from "@/services/StatService"

export default {
  setup() {
    const router = useRouter()
    const service = new ReleasesService()
    const collectionComponent = ref()
    const backendVersion = ref()
    const frontendVersion = ref()
    const statService = new StatService()

    loadStats()

    function loadStats() {
      statService.getVersion().then((result) => {
        backendVersion.value = result.data
      })
      statService.getFrontendVersion().then((result) => {
        frontendVersion.value = result.data.version
      })
    }

    function loadPart(part: Number, sort: any) {
      service.getRunConfigurations(part, sort).then((result: ResultSet<any>) => {
        if (collectionComponent.value) collectionComponent.value.onPartLoaded(part, sort, result)
      })
    }

    function onRunConfigChanged() {
      if (collectionComponent.value) collectionComponent.value.reload()
    }

    const sortOptions = ref([
      // {label: 'Version ↓', value: 'version,asc'},
      // {label: 'Version ↑', value: 'version,desc'},
      { label: "Release Date ↓", value: "createdTime,asc" },
      { label: "Release Date ↑", value: "createdTime,desc" },
      { label: "Status ↓", value: "status,asc" },
      { label: "Status Date ↑", value: "status,desc" },
    ])

    function onNew() {
      router.push({ name: "newComputer" })
    }

    return {
      sortOptions,
      collectionComponent,
      loadPart,
      onRunConfigChanged,
      onNew,
      backendVersion,
      frontendVersion,
    }
  },
}
